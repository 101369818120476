/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require("bootstrap/dist/css/bootstrap.min.css")
require('../css/app.css');
require("../css/animate.css");
require("../css/base.css");
require("../css/aos.css");
require("../css/carousel.css");
require("../css/floating-page-nav.css");
require("../css/menu.css");
require("../css/loading-spinner.css");
require("../css/more-options.css");
require("../slick/slick.css");
require("../slick/slick-theme.css");
require("../css/quick-links.css");
require('bootstrap');
require("tail.datetime/css/tail.datetime-default-blue.min.css");
global.jQuery = global.$ = require('jquery'),
global.ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
global.tailDateTime = require("tail.datetime/js/tail.datetime-full.min")

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
